<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Tickets</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Tickets</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>
      <div v-else>
        <div v-if="tickets.length == 0" class="ion-text-center loading_spinner">
          <ion-text color="danger">
            <h2>Non hai ticket assegnati.</h2>
          </ion-text>
        </div>
        <div v-else>
          <div class="ion-padding-horizontal ion-text-center">
            <ion-text color="primary">
              <h5>Consulta i ticket di assistenza a te assegnati</h5>
            </ion-text>
          </div>
          <div style="padding: 10px">
            <Calendar
              :value="date"
              is-expanded
              :attributes="setAttributes"
              @dayclick="dayClicked"
              ref="refCalendar"
            >
            </Calendar>
            <!-- Add a column to display info for clicked day -->
            <div v-if="selectedDay" class="selected-day">
              <h3 class="ion-text-center">
                {{
                  selectedDay.date.toLocaleDateString("it-IT", {
                    weekday: "long",
                    day: "numeric",
                  })
                }}
              </h3>
              <div v-if="selectedDay.attributes != 0">
                <ion-list>
                  <ion-item
                    v-for="attr in selectedDay.attributes"
                    :key="attr.key"
                    @click="openDetail(attr.customData.tickets_id)"
                    button="true"
                    detail
                  >
                    <ion-label>
                      <ion-text color="primary">
                        Cliente: {{ riferimentoCliente(attr.customData) }}
                      </ion-text>
                      <!-- <h3>
                        Impianto:
                        {{ riferimentoImpianto(attr.customData) }}
                      </h3> -->
                      <div class="ticket_status">
                        <h3>Stato:</h3>
                        <ion-badge
                          :color="
                            statoTicket(attr.customData.tickets_status_id)
                          "
                        >
                          {{ attr.customData.tickets_status_value }}
                        </ion-badge>
                      </div>
                      <h3>
                        Messaggio:
                        {{ parseHtmlEnteties(attr.customData.tickets_message) }}
                      </h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <div v-else>
                <ion-text color="danger">
                  <div class="ion-text-center ion-margin-top font-bold">
                    Non hai ticket assegnati nella giornata selezionata
                  </div>
                </ion-text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button color="success" @click="getTickets()">
          <ion-icon :icon="refresh"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Calendar } from "v-calendar";

import apiTicket from "../services/ticket";
import { openToast } from "../services/toast";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { refresh } from "ionicons/icons";

export default {
  name: "Tab3",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonBadge,
    IonIcon,
    IonFab,
    IonFabButton,
    Calendar,
  },
  setup() {
    const router = useRouter();
    const tickets = ref([]);
    const loading = ref(false);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const date = ref(new Date());
    const selectedDay = ref(null); // Add state to store selected day

    const refCalendar = ref(null);

    /**
     *
     * Convert tickets array into attributes for calendar visualization
     * Assign key derived from original ticket.tickets_id field
     * Assign ticket element to attribute's customData property to access original ticket in template
     * Assign tickets_data_assegnazione as date to use in calendar visualization
     */
    const setAttributes = computed(() => {
      return tickets.value.map((ticket) => ({
        key: `ticket.${ticket.tickets_id}`,
        highlight: {
          color: "blue",
          fillMode: "light",
        },
        dates: ticket.tickets_assign_date,
        customData: ticket,
      }));
    });
    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    const dayClicked = (day) => {
      selectedDay.value = day;
    };

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    // const riferimentoCliente = computed(() => {
    //   return (ticket) => {
    //     if (
    //       ticket.customers_name != null &&
    //       ticket.customers_last_name != null
    //     ) {
    //       return `${ticket.customers_name} ${ticket.customers_last_name}`;
    //     } else {
    //       return `${ticket.customers_company}`;
    //     }
    //   };
    // });
    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }
    /**
     *
     * Print project code and customers address or " - " based on condition
     *
     */
    const riferimentoImpianto = computed(() => {
      return (impianto) => {
        if (impianto.projects_codice && impianto.customers_address) {
          return `${impianto.projects_codice} - ${impianto.customers_address}`;
        } else {
          return " - ";
        }
      };
    });

    /**
     *
     * Set correct background for ticket status
     *
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //aperto
          className = "secondary";
        } else if (statusId == 2) {
          //in lavorazione
          className = "warning";
        } else if (statusId == 3) {
          //attesa rsposta
          className = "tertiary";
        } else if (statusId == 4) {
          //in pausa
          className = "danger";
        } else {
          //chiuso
          className = "success";
        }
        return className;
      };
    });

    /**
     *
     * Remove html tag from ticket description
     *
     */
    function parseHtmlEnteties(str) {
      const strippedString = str.replace(/(<([^>]+)>)/gi, "");
      return strippedString.replace(
        /&#([0-9]{1,3});/gi,
        function (match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        }
      );
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;
    }

    /**
     *
     * Passing id to retrive detail in InterventoDetail page
     *
     */
    function openDetail(id: string) {
      router.push(`tab3/${id}`);
    }

    function getTickets() {
      loading.value = true;
      /* GET TICKET OPENED FOR THE USER */
      apiTicket
        .getTickets(userID)
        .then((response) => {
          //console.log(response);
          tickets.value = response;
          selectedDay.value = null;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei ticket", "danger");
          console.error(error);
        })
        .finally(() => {
          //console.log(setAttributes(tickets.value));
          loading.value = false;
          //console.log("fine");
        });
    }

    onMounted(() => {
      //console.log(refCalendar.value.$theme);
      getTickets();
    });

    return {
      loading,
      tickets,
      riferimentoCliente,
      riferimentoImpianto,
      dateFormat,
      openDetail,
      statoTicket,
      date,
      setAttributes,
      dayClicked,
      selectedDay,
      parseHtmlEnteties,
      //...
      refCalendar,
      refresh,
      getTickets,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.ticket_status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ticket_status h3 {
  margin-right: 10px;
}
.font-bold {
  font-weight: 600;
}
</style>